import {SmartComponent} from "smart-component-js";
import Swiper from 'swiper/bundle';


class CarouselComponent extends SmartComponent {
    constructor(element, parentComponent, params) {
        params = params || {};
        super(element, parentComponent, params);
        this.$el = $(element);

        this.isRtl = KD_COMMON_CONSTANTS.IS_RTL;

        this.initCarousel();
    }


    initCarousel() {
        if (this.$el.is(".black-quote")) {
            this.$el.find('.owl-carousel').owlCarousel({
                items: 1,
                rtl: this.isRtl,
                loop: this.$el.find('.owl-carousel .card__image img').length > 3,
                margin: 25,
                nav: true,
                navText: ['<span class="am-prev"><img src="/fe-web/img/marangoni/icons/long-arrow-left-white.svg" /></span>',
                    '<span class="am-next"><img src="/fe-web/img/marangoni/icons/long-arrow-right-white.svg" /></span>'],
                dots: false,
                responsive: {
                    0: {
                        items: 1
                    },
                    768: {
                        items: 2
                    },
                    1024: {
                        items: 3
                    },
                    1440: {
                        items: 3
                    },
                }
            })
        }
        if (this.$el.is(".black")) {
            this.$el.find('.owl-carousel').owlCarousel({
                items: 1,
                rtl: this.isRtl,
                loop: this.$el.find('.owl-carousel .card__image img').length > 3,
                margin: 25,
                nav: true,
                navText: ['<span class="am-prev"><img src="/fe-web/img/marangoni/icons/long-arrow-left-white.svg" /></span>',
                    '<span class="am-next"><img src="/fe-web/img/marangoni/icons/long-arrow-right-white.svg" /></span>'],
                dots: false,
                responsive: {
                    0: {
                        items: 1
                    },
                    768: {
                        items: 2
                    },
                    1024: {
                        items: 3
                    },
                    1440: {
                        items: 4
                    },
                }
            })
        }
        if (this.$el.is(".four-white")) {
            this.$el.find('.owl-carousel').owlCarousel({
                items: 1,
                rtl: this.isRtl,
                loop: this.$el.find('.owl-carousel .card__image img').length > 3,
                margin: 25,
                nav: true,
                navText: ['<span class="am-prev"><img src="/fe-web/img/marangoni/icons/long-arrow-left-gray.svg" /></span>',
                    '<span class="am-next"><img src="/fe-web/img/marangoni/icons/long-arrow-right-gray.svg" /></span>'],
                dots: false,
                responsive: {
                    0: {
                        items: 1
                    },
                    768: {
                        items: 2
                    },
                    1024: {
                        items: 3
                    },
                    1440: {
                        items: 4
                    },
                }
            })
        }
        if (this.$el.is(".minimal")) {
            this.$el.find('.owl-carousel').owlCarousel({
                items: 1,
                rtl: this.isRtl,
                loop: this.$el.find('.owl-carousel .card__image img').length > 3,
                margin: 25,
                nav: true,
                navText: ['<span class="am-prev"><img src="/fe-web/img/marangoni/icons/long-arrow-left-white.svg" /></span>',
                    '<span class="am-next"><img src="/fe-web/img/marangoni/icons/long-arrow-right-white.svg" /></span>'],
                dots: false,
                responsive: {
                    0: {
                        items: 1
                    },
                    768: {
                        items: 2
                    },
                    1024: {
                        items: 3
                    },
                    1440: {
                        items: 4
                    },
                }
            })
        }
        if (this.$el.is(".grey")) {
            this.$el.find('.owl-carousel').owlCarousel({
                rtl: this.isRtl,
                loop: this.$el.find('.owl-carousel .card__image img').length > 3,
                margin: 25,
                stagePadding: 1,
                nav: true,
                dots: false,
                navText: ['<span class="am-prev"><img src="/fe-web/img/marangoni/icons/long-arrow-left-gray.svg" /></span>',
                    '<span class="am-next"><img src="/fe-web/img/marangoni/icons/long-arrow-right-gray.svg" /></span>'],
                responsive: {
                    0: {
                        items: 1
                    },
                    768: {
                        items: 2
                    },
                    1280: {
                        items: 2
                    },
                    1440: {
                        items: 3
                    }
                }
            })
        }
        if (this.$el.is(".white")) {
            this.$el.find('.owl-carousel').owlCarousel({
                rtl: this.isRtl,
                loop: this.$el.find('.owl-carousel .card__image img').length > 3,
                margin: 25,
                nav: true,
                dots: false,
                navText: ['<span class="am-prev"><img src="/fe-web/img/marangoni/icons/long-arrow-left-gray.svg" /></span>',
                    '<span class="am-next"><img src="/fe-web/img/marangoni/icons/long-arrow-right-gray.svg" /></span>'],
                responsive: {
                    0: {
                        items: 1
                    },
                    768: {
                        items: 2
                    },
                    1280: {
                        items: 2
                    },
                    1440: {
                        items: 3
                    }
                }
            })
        }
        if (this.$el.is(".dark-grey")) {
            this.$el.find('.owl-carousel').owlCarousel({
                rtl: this.isRtl,
                loop: this.$el.find('.owl-carousel .card__image img').length > 3,
                margin: 25,
                stagePadding: 1,
                nav: true,
                dots: false,
                navText: ['<span class="am-prev"><img src="/fe-web/img/marangoni/icons/long-arrow-left-white.svg" /></span>',
                    '<span class="am-next"><img src="/fe-web/img/marangoni/icons/long-arrow-right-white.svg" /></span>'],
                responsive: {
                    0: {
                        items: 1
                    },
                    768: {
                        items: 2
                    },
                    1280: {
                        items: 2
                    },
                    1440: {
                        items: 3
                    }
                }
            })
        }

        if (this.$el.is(".white-cta")) {
            this.$el.find('.owl-carousel').owlCarousel({
                rtl: this.isRtl,
                loop: this.$el.find('.owl-carousel .card__image img').length > 3,
                margin: 25,
                stagePadding: 1,
                dots: false,
                nav: true,
                navText: ['<span class="am-prev"><img src="/fe-web/img/marangoni/icons/long-arrow-left-gray.svg" /></span>',
                    '<span class="am-next"><img src="/fe-web/img/marangoni/icons/long-arrow-right-gray.svg" /></span>'],
                responsive: {
                    0: {
                        items: 1
                    },
                    768: {
                        items: 2
                    },
                    1280: {
                        items: 2
                    },
                    1440: {
                        items: 3
                    }
                }
            })
        }
        if (this.$el.is(".grey-cta")) {
            this.$el.find('.owl-carousel').owlCarousel({
                rtl: this.isRtl,
                loop: this.$el.find('.owl-carousel .card__image img').length > 3,
                margin: 25,
                dots: false,
                nav: true,
                // navText: [$('.am-prev'), $('.am-next')],
                navText: ['<span class="am-prev"><img src="/fe-web/img/marangoni/icons/long-arrow-left-gray.svg" /></span>',
                    '<span class="am-next"><img src="/fe-web/img/marangoni/icons/long-arrow-right-gray.svg" /></span>'],
                responsive: {
                    0: {
                        items: 1
                    },
                    768: {
                        items: 2
                    },
                    1280: {
                        items: 2
                    },
                    1440: {
                        items: 3
                    }
                }
            })
        }

    }
}

export default CarouselComponent;
import {SmartComponent} from "smart-component-js";
import IMUtils from "../business/IMUtils";

class HeroBannerComponent extends SmartComponent {
    constructor(element, parentComponent, params) {
        params = params || {};
        params.className = "HeroBannerComponent";
        super(element, parentComponent, params);
        this.isRtl = KD_COMMON_CONSTANTS.IS_RTL;
        this.$wrapper = $(element);

        this.chevronTimeout = false;

        this.init();
    }

    init() {
        $("body").addClass("ab-test-active");

        $(window).on('scroll', () => {
            this.fakeDebounceChevron();
        });

        this.$owl = this.$wrapper.find('.owl-carousel.hero-banner-container');
        this.$owlDots = this.$wrapper.find('#owl-dots');

        this.$owl.on('resized.owl.carousel', () => {
            this.resize();
        });
        this.$owl.on('initialized.owl.carousel', () => {
            this.resizeSlideContents();
            this.resize();
        });

        this.initializeSwipers();

        if (this.$wrapper.parents('.hidden-forced').length > 0) {
            this.$wrapper.parents('.hidden-forced').removeClass('hidden-forced');
        }

        this.$wrapper.on('click', '.owl-dot', (e) => {
            this.$owl.trigger('to.owl.carousel', [$(e.currentTarget).parents('.owl-item').index(), 300]);
        });
    }

    initializeSwipers() {
        this.$owl.owlCarousel({
            loop: true,
            rtl: this.isRtl,
            margin: 0,
            autoHeight: true,
            nav: false,
            dots: true,
            autoplay: true,
            autoplayHoverPause: true,
            autoplayTimeout: 6000,
            dotsContainer: 'owl-dots hero-banner-owl-dots',
            responsive: {
                0: {
                    items: 1
                }, 1000: {
                    items: 1
                }
            },
            onChanged: (event) => {
                this.onSlideChange(event.page.index);
            }
        });

        this.onSlideChange(0);

        this.$owlDots.owlCarousel({
            margin: 15,
            rtl: this.isRtl,
            autoHeight: true,
            autoWidth: true,
            nav: false,
            dots: false,
            responsive: {
                0: {
                    margin: 15
                }, 768: {
                    margin: 65
                }
            },
        });

        this.resize();
    }

    resizeSlideContents() {
        if (IMUtils.isMobile()) {
            let minHeight = 0;
            this.$wrapper.find('.hero-banner-item .hero-banner-content').each((i, el) => {
                if ($(el).outerHeight() > minHeight) {
                    minHeight = $(el).outerHeight();
                }
            });
            this.$wrapper.find('.hero-banner-item .hero-banner-content').css('min-height', parseInt(minHeight) + 10);
        }
    }

    /**
     * Fake Debounce perchè è solo una funzione che riduce il numero di occorrenze, ma ogni 200ms esegue un update
     */
    fakeDebounceChevron() {
        if (this.chevronTimeout === false) {
            this.chevronTimeout = true;
            setTimeout(() => {
                this.chevronTimeout = false;
                this.chevronThings();
            }, 200);
        }
    }

    chevronThings() {
        this.$wrapper.find('.chevron').toggle(($(window).scrollTop() + $(window).height()) < ($('#hero-banner').offset().top + $('#hero-banner').height() + 50));
    }

    onSlideChange(index) {
        this.$wrapper.find('#owl-dots .owl-dot.active').removeClass('active');
        this.$wrapper.find('#owl-dots .owl-dot:eq(' + (index) + ')').addClass('active');

        let $activeSlide = this.$wrapper.find('.owl-item:not(.cloned):eq(' + (index) + ')');
        $activeSlide.find('.hero-banner-content').removeClass('overflowing');
        if (!IMUtils.isMobile()) {
            let overflowing = ((this.$wrapper.height() * 0.8) - this.$wrapper.find('#owl-dots').outerHeight() - $activeSlide.find('.hero-banner-content').height()) < 0;
            if (overflowing) {
                $activeSlide.find('.hero-banner-content').addClass('overflowing');
            }
        }

        if (index > 0) {
            index--
        }
        this.$owlDots.trigger('to.owl.carousel', [index, 300]);

        this.resize();
    }

    resize() {
        if (IMUtils.isMobile()) {

            setTimeout(() => {
                let dotTop = parseInt(this.$wrapper.find('.active .hero-banner-image').outerHeight()) - 50;
                this.$wrapper.find('#owl-dots').addClass('show').css('top', dotTop);
            }, 100);

            //Su mobile bisogna forzare le cta in modo che siano tutte larghe uguali, ma questo solo se sono più di 1 ovviamente.
            this.$wrapper.find('.hero-banner-btns').each((i, el) => {
                let btns = $(el).find('.btn-banner.tsp').width('');
                if (btns.length > 1) {
                    let maxWidth = 0;
                    btns.each((j, bel) => {
                        if (maxWidth < $(bel).width()) {
                            maxWidth = $(bel).width();
                        }
                    })
                    btns.width(parseInt(maxWidth));
                }
            });
        } else {
            this.$wrapper.find('#owl-dots').css('top', 'auto');
            this.$wrapper.find('.hero-banner-btns .btn-banner.tsp').width('auto');
        }

        this.fakeDebounceChevron();
    }
}

export default HeroBannerComponent;
import {SmartComponent} from "smart-component-js";
import $ from "jquery";
import TrackerManager from "../../common/business/TrackerManager";

class AreaComponent extends SmartComponent {
    constructor(element, parentComponent, params) {
        params = params || {};
        params.className = "AreaComponent";
        super(element, parentComponent, params);

        this.form = $(element);
        this.siteId = element.dataset.siteId;
        this.locale = element.dataset.locale;
        this.labelReadMore = element.dataset.labelReadMore;

        this.allFiltersOnEmpty = !!element.dataset.allFiltersOnEmpty;

        this.italianarea = "";
        this.italiantypology = "";
        this.italiancampus = "";
        this.italianintake = "";

        this.page = 1;

        this.loadAttributes(element);

        this.aiClusterTypologyMap = typeof aiClusterTypologyMap == 'undefined' ? false : JSON.parse(aiClusterTypologyMap);
        this.aiClusterAreaMap = typeof aiClusterAreaMap == 'undefined' ? false : JSON.parse(aiClusterAreaMap);

        if (!!this.form.data('prefilledCampusValue')) {
            this.campusFilterValues.push(this.form.data('prefilledCampusValue'));
        }

        if (!!this.form.data('prefilledTypologyValue')) {
            this.typologyFilter = this.form.data('prefilledTypologyValue');
        }


        this.init();
    }

    loadAttributes(element) {
        this.areaFilter = element.dataset.areaId || null;
        this.forcedArea = !!element.dataset.areaId
        this.campusFilterValues = !element.dataset.campusId ? [] : [element.dataset.campusId];
        this.forcedCampus = !!element.dataset.campusId
        this.typologyFilter = element.dataset.typologyId || null;
        this.forcedTypology = !!element.dataset.typologyId
        this.intakeFilterValues = !element.dataset.intakeId ? [] : [element.dataset.intakeId];
        this.forcedIntake = !!element.dataset.intakeId
    }

    init() {
        $('#confirmArea').click(() => {
            this.confirmSelectModal('area');
            this.search();
        });

        $('#confirmCampus').click(() => {
            this.confirmCheckboxModal('campus');
            this.search();
        });

        $('#confirmPrograms').click(() => {
            this.confirmSelectModal('typology');
            this.search();
        })

        $('#confirmIntake').click(() => {
            this.confirmCheckboxModal('intake');
            this.search();
        })

        $('#search').click(() => {
            this.search();
        })

        $('#loadMore').click(() => {
            this.loadMoreItems();
        })

        this.readFilters();
        // this.search(true);
    }

    confirmCheckboxModal(checkboxId) {
        if ($(this.element).find(".input-placeholder-value.modal-trigger." + checkboxId).length > 0) {
            this[checkboxId + 'FilterValues'] = [];
            this['italian' + checkboxId] = '';

            let $checkedItems = $('[name="' + checkboxId + '"]:checked');
            if ($checkedItems.length > 0) {
                let label = "";
                for (var i = 0; i < $checkedItems.length; i++) {
                    let $item = $($checkedItems[i]);
                    label += $item.data("label");
                    this['italian' + checkboxId] += $item.data("italianLabel");
                    this[checkboxId + 'FilterValues'][i] = $item.val();

                    if (i != $checkedItems.length - 1) {
                        this['italian' + checkboxId] += ',';
                        label += ", "
                    }
                }
                $(this.element).find(".input-placeholder-value.modal-trigger." + checkboxId).text(label)
            } else {
                let label = $(this.element).find(".input-placeholder-value.modal-trigger." + checkboxId).data("defaultText");
                $(this.element).find(".input-placeholder-value.modal-trigger." + checkboxId).text(label)
            }
        }
    }

    confirmSelectModal(selectId) {
        this['italian' + selectId] = "";
        this[selectId + 'Filter'] = null;

        let $item = $('#modal_' + selectId + ' .hoax-input-filter-options .item.selected');
        if ($item.data("value")) {
            this[selectId + 'Filter'] = $item.data("value");
            $(this.element).find(".input-placeholder-value.modal-trigger." + selectId).text($item.data("label"));
            this['italian' + selectId] = $item.data('italianLabel');
        } else {
            let label = $(this.element).find(".input-placeholder-value.modal-trigger." + selectId).data("defaultText");
            $(this.element).find(".input-placeholder-value.modal-trigger." + selectId).text(label)
        }
    }

    search(firstInit) {
        this.page = 1;
        if (!firstInit) {
            this.trackSearchCourseGa();
            this.trackSearchCourseYxp();
        }
        this.doAjaxCall().then((resp) => {
            let html = "";
            const limit = 9;

            for (var i = 0; i < resp.body.result.length; i++) {
                const hiddenByDefault = limit - (i + 1) < 0;
                html += this.createResultItem(resp.body.result[i], hiddenByDefault);

            }

            $(this.element).find(".search-result-container").html(html);
            $('#counterNumber').text(resp.body.totalRows);

            if (!firstInit && $(window).width() <= 768) {
                let top = $(this.element).find(".search-result").offset().top;
                top = top - $('header').height();
                $('html, body').stop().animate({scrollTop: top}, 500, 'swing');
            }

            if ((this.page * resp.body.pageSize) < resp.body.totalRows) {
                $(this.element).find("#loadMore").show();
            } else {
                $(this.element).find("#loadMore").hide();
            }
        });
    }

    async fakeLoadingCard(element) {
        return new Promise((resolve, reject) => {
            $(element).css({'opacity': 0}).removeClass('d-none').animate({
                opacity: 1,
            }, 300, () => {
                resolve()
            })
        })
    }

    async showMoreItems(items = null) {

        const hiddenCards = $(this.element).find(".search-result-container .search-result-item--wrapper.d-none")
        let missing = hiddenCards.length

        let counter = 0
        for (let card of hiddenCards) {
            if (items && counter >= items) {
                break;
            }
            await (this.fakeLoadingCard(card))
            counter++

        }

        return missing - counter

    }

    loadMoreItems() {
        let prevPosition = $('html, body').scrollTop();

        this.showMoreItems(9).then((res) => {
            if (res < 1) {
                $(this.element).find("#loadMore").hide();
            }
            $('html, body').scrollTop(prevPosition);
        })

        /*this.page = this.page + 1;
         this.doAjaxCall().then((resp) => {
         let html = "";
         for (var i = 0; i < resp.body.result.length; i++) {
         html += this.createResultItem(resp.body.result[i]);
         }

         $(this.element).find(".search-result-container").append(html);
         if ((this.page * resp.body.pageSize) < resp.body.totalRows) {
         $(this.element).find("#loadMore").show();
         } else {
         $(this.element).find("#loadMore").hide();
         }

         $('html, body').scrollTop(prevPosition);
         });*/
    }

    doAjaxCall() {
        let qs = this.getFilterQueryString();

        return youserxp.ajax(KD_MARANGONI_SITE_CONSTANTS.API_ENDPOINT + `/course/list?site=${this.siteId}&locale=${this.locale}&${qs}`, {
            method: 'GET',
        });
    }

    getFilterQueryString() {
        let realFilters = '';
        let defaultFilters = '';

        if (!this.areaFilter && this.allFiltersOnEmpty && this.form.find('.search-filters #modal_area select[name="area"]').length > 0) {
            this.form.find('.search-filters #modal_area select[name="area"] option:not(.default-option)').each((i, el) => {
                defaultFilters += `&area=${$(el).attr('value')}`;
            });
        } else if (!!this.areaFilter) {
            realFilters += `&area=${this.areaFilter}`;
        }

        if (this.campusFilterValues.length > 0) {
            this.campusFilterValues.forEach((it) => {
                realFilters += `&campus=${it}`;
            });
        } else if (this.allFiltersOnEmpty) {
            this.form.find('.search-filters #modal_campus input[name="campus"]').each((i, el) => {
                defaultFilters += `&campus=${$(el).attr('value')}`;
            });
            defaultFilters += '&allcampus=true';
        }

        if (!this.typologyFilter && this.allFiltersOnEmpty && this.form.find('.search-filters #modal_typology select[name="typology"]').length > 0) {
            this.form.find('.search-filters #modal_typology select[name="typology"] option:not(.default-option)').each((i, el) => {
                defaultFilters += `&typology=${$(el).attr('value')}`;
            });
        } else if (!!this.typologyFilter) {
            realFilters += `&typology=${this.typologyFilter}`;
        }

        if (this.intakeFilterValues.length > 0) {
            this.intakeFilterValues.forEach((it) => {
                realFilters += `&intake=${it}`;
            });
        } else if (this.allFiltersOnEmpty) {
            this.form.find('.search-filters #modal_intake input[name="intake"]').each((i, el) => {
                defaultFilters += `&intake=${$(el).attr('value')}`;
            });
        }

        let qs = `page=${this.page}${this.processDoubleFilters(realFilters)}`;
        this.writeFilters(qs);

        qs += '&' + this.processDoubleFilters(defaultFilters);
        return qs;
    }

    writeFilters(qs) {
        window.history.replaceState(null, document.title, '?qs=' + encodeURIComponent(qs));
    }

    readFilters() {
        let params = document.location.search;
        if (params.length > 1 && params.indexOf('qs=') >= 0) {
            let qs = params.split('qs=')[1];
            if (qs.indexOf('&') >= 0) {
                qs = qs.split('&')[0];
            }

            qs = decodeURIComponent(qs);

            let splitted = qs.split('&');
            let filterObj = {};

            for (let i in splitted) {
                let filter = splitted[i];
                if (filter.indexOf('=') > 0) {
                    filter = filter.split('=');

                    if (!filterObj[filter[0]]) {
                        filterObj[filter[0]] = [];
                    }
                    filterObj[filter[0]].push(filter[1]);
                }
            }

            if (!this.forcedArea && !!filterObj['area'] && filterObj['area'].length == 1) {
                this.form.find('.search-filters #modal_area select[name="area"]').val(filterObj['area'][0]);
                $('#modal_area .hoax-input-filter-options .item.selected').removeClass('selected');
                $('#modal_area .hoax-input-filter-options .item[data-value="' + filterObj['area'][0] + '"]').addClass('selected');
                this.confirmSelectModal('area');
            }
            if (!this.forcedCampus && !!filterObj['campus']) {
                for (let i in filterObj['campus']) {
                    let c = filterObj['campus'][i];
                    this.form.find('.search-filters #modal_campus input[name="campus"][value*="' + c + '"]').prop('checked', true);
                    this.campusFilterValues.push(c);
                }
                this.confirmCheckboxModal('campus');
            }
            if (!this.forcedTypology && !!filterObj['typology'] && filterObj['typology'].length == 1) {
                this.form.find('.search-filters #modal_typology select[name="typology"]').val(filterObj['typology'][0]);
                $('#modal_typology .hoax-input-filter-options .item.selected').removeClass('selected');
                $('#modal_typology .hoax-input-filter-options .item[data-value="' + filterObj['typology'][0] + '"]').addClass('selected');
                this.confirmSelectModal('typology');
            }
            if (!this.forcedIntake && !!filterObj['intake']) {
                for (let i in filterObj['intake']) {
                    let c = filterObj['intake'][i];
                    this.form.find('.search-filters #modal_intake input[name="intake"][value*="' + c + '"]').prop('checked', true);
                    this.intakeFilterValues.push(c);
                }
                this.confirmCheckboxModal('intake');
            }
            if (!!filterObj['page']) {
                this.page = filterObj['page'][0];
            }

            this.search();
        }
    }

    processDoubleFilters(filterString) {
        let res = '';
        if (!!filterString && filterString.length > 2) {
            let splitted = filterString.split('&');
            for (let i in splitted) {
                if (splitted[i].length > 0) {
                    if (splitted[i].indexOf(',') > 0) {
                        let squareSplitted = splitted[i].split('=');
                        let cubeSplitted = squareSplitted[1].split(',');
                        for (let j in cubeSplitted) {
                            res += '&' + squareSplitted[0] + '=' + cubeSplitted[j];
                        }
                    } else {
                        res += '&' + splitted[i];
                    }
                }
            }
        }

        return res;
    }

    createResultItem(item, hidden = false) {
        let desc = item.subtitle || '';
        if (desc.length > 99) {
            desc = desc.substr(0, 98) + '...';
        }

        return `

            <div class="col-lg-4 col-md-6 search-result-item--wrapper ${hidden ? 'd-none' : ''}">
                <div class="search-result-item--corso">
                    <a href="${item.url}"><img src="${item.desktopImage}" /></a>
                    <div class="content">
                        <div class="title">
                            <a href="${item.url}">${item.title}</a>
                        </div>
                        <div class="description">
                            ${desc}
                        </div>
                        ${this.printTagElements(item.degreeLevel)}
                        ${this.printTagElements(item.campus)}
                         <a href="${item.url}" class="action btn-mrng">${this.labelReadMore}</a>
                    </div>
                </div>
            </div>`;
    }

    printTagElements(label) {
        if (label) {
            label = label.replace(/design/gi, '');
            label = label.replace(/fashion/gi, '').trim();
            return `<div class="tags">
                    <div class="tag">${label}</div>
                </div>`;
        } else {
            return "";
        }

    }

    printHashtagElements(hashtagList) {
        if (hashtagList && hashtagList.length > 0) {
            let html = "";
            for (var i = 0; i < hashtagList.length; i++) {
                html += `<div class="hashtag">
                ${hashtagList[i]}
               </div>`;
            }
            return html;
        } else {
            return "";
        }

    }

    trackSearchCourseYxp() {
        let typologyKey = !this.typologyFilter ? -1 : this.typologyFilter;
        let trackId = false;

        if (this.aiClusterAreaMap) {
            if (!!this.areaFilter) {
                trackId = this.aiClusterAreaMap[this.areaFilter][typologyKey];
            }
        } else if (this.aiClusterTypologyMap) {
            trackId = this.aiClusterTypologyMap[typologyKey];
        }

        if (trackId) {
            TrackerManager.track('PAGE_VIEW', {
                eventAction: 'VIEW_AREA_DEGREE_LEVEL',
                entityName: 'aicluster',
                entityId: trackId
            });
        }
    }

    trackSearchCourseGa() {
        window.dataLayer = window.dataLayer || [];
        let items = {
            'event': 'search-course'
        };

        if (!!this.italiantypology) {
            items['search-program'] = this.italiantypology;
        }
        if (!!this.italiancampus) {
            items['search-campus'] = this.italiancampus;
        }

        window.dataLayer.push(items);
    }
}

export default AreaComponent;
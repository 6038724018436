import {SmartComponentManager} from "../common/smart-component-fix-ie11";
import "../common/custom-event-polyfill";

import DefaultCtrl from "../common/controllers/DefaultCtrl";

import NavigationComponent from "./components/NavigationComponent";
import NoHeaderComponent from "./components/NoHeaderComponent";
import CommonComponent from "./components/CommonComponent";
import FormComponent from "./components/FormComponent";
import ContactFormComponent from "./components/ContactFormComponent";
import VideoComponent from "./components/VideoComponent";
import ProjectsListingComponent from "./components/ProjectsListingComponent";
import NewsEventListingComponent from "./components/NewsEventListingComponent";
import ArticleListingComponent from "./components/ArticleListingComponent";
import AlumniListingComponent from "./components/AlumniListingComponent";
import MentorListingComponent from "./components/MentorListingComponent";
import SupermentorListingComponent from "./components/SupermentorListingComponent";
import AmbassadorListingComponent from "./components/AmbassadorListingComponent";
import ScholarshipListingComponent from "./components/ScholarshipListingComponent";
import PromoterListingComponent from "./components/PromoterListingComponent";
import AreaComponent from "./components/AreaComponent";
import ThankYouComponent from "./components/ThankYouComponent";
import MapComponent from "./components/MapComponent";
import MapComponentCn from "./components/MapComponentCn";
import ContactToolComponent from "./components/ContactToolComponent";
import HighlightContainerComponent from "./components/HighlightContainerComponent";
import ShareComponent from "./components/ShareComponent";
import ApplyPopupComponent from "./components/ApplyPopupComponent";
import WizardComponent from "./components/WizardComponent";
import GalleryListingComponent from "./components/GalleryListingComponent";
import ProjectDetailComponent from "./components/ProjectDetailComponent";
import GalleryDetailComponent from "./components/GalleryDetailComponent";
import CustomTabComponent from "./components/CustomTabComponent";
import TeacherStaffPopupComponent from "./components/TeacherStaffPopupComponent";
import BackgroundVideoComponent from "./components/BackgroundVideoComponent";
import CrossPopupComponent from "./components/CrossPopupComponent";
import AiHeroCardComponent from "./components/AiHeroCardComponent";
import AiNewsCardComponent from "./components/AiNewsCardComponent";
import AiProjectCardComponent from "./components/AiProjectCardComponent";
import AiCourseCardComponent from "./components/AiCourseCardComponent";
import TrackingComponent from "../common/components/TrackingComponent";
import ClampComponent from "./components/ClampComponent";
import OpenLessonFormComponent from "./components/OpenLessonFormComponent";
import DateMaskingComponent from "./components/DateMaskingComponent";
import CallMeBackInnerComponent from "./components/CallMeBackInnerComponent";
import IframeThankYouComponent from "./components/IframeThankYouComponent";
import MagazineFrontpageComponent from "./components/MagazineFrontpageComponent";
import MagazineListingComponent from "./components/MagazineListingComponent";
import LatestTribuneComponent from "./components/LatestTribuneComponent";
import SlidingArrowsComponent from "./components/SlidingArrowsComponent";
import HeroBannerComponent from "./components/HeroBannerComponent";
import MobileSliderComponent from "./components/MobileSliderComponent";
import CarouselComponent from "./components/CarouselComponent";
import DesktopSliderComponent from "./components/DesktopSliderComponent";
import PopupVideoThumbsComponent from "./components/PopupVideoThumbsComponent";
import CampusInfoComponent from "./components/CampusInfoComponent";
import VideoSectionComponent from "./components/VideoSectionComponent";

import ScholarshipDetailCtrl from "./controllers/ScholarshipDetailCtrl";
import ScholarshipFormCtrl from "./controllers/ScholarshipFormCtrl";
import ScholarshipOptinCtrl from "./controllers/ScholarshipOptinCtrl";
import ScholarshipResetPwdCtrl from "./controllers/ScholarshipResetPwdCtrl";
import ScholarshipRegisteredCtrl from "./controllers/ScholarshipRegisteredCtrl";
import CourseDetailCtrl from "./controllers/CourseDetailCtrl";

import "../../less/marangoni/gge-marangoni.less";


import AiManager from "../common/business/AiManager";
import IMUtils from "./business/IMUtils";

SmartComponentManager.registerComponents({
    DefaultCtrl,

    NavigationComponent,
    NoHeaderComponent,
    CommonComponent,
    FormComponent,
    ContactFormComponent,
    VideoComponent,
    ProjectsListingComponent,
    NewsEventListingComponent,
    ArticleListingComponent,
    AlumniListingComponent,
    MentorListingComponent,
    SupermentorListingComponent,
    AmbassadorListingComponent,
    ScholarshipListingComponent,
    PromoterListingComponent,
    AreaComponent,
    ThankYouComponent,
    MapComponent,
    MapComponentCn,
    ContactToolComponent,
    HighlightContainerComponent,
    ShareComponent,
    LatestTribuneComponent,
    ApplyPopupComponent,
    WizardComponent,
    GalleryListingComponent,
    ProjectDetailComponent,
    MagazineListingComponent,
    MagazineFrontpageComponent,
    GalleryDetailComponent,
    CustomTabComponent,
    TeacherStaffPopupComponent,
    BackgroundVideoComponent,
    CrossPopupComponent,
    AiHeroCardComponent,
    AiNewsCardComponent,
    AiProjectCardComponent,
    AiCourseCardComponent,
    TrackingComponent,
    ClampComponent,
    OpenLessonFormComponent,
    DateMaskingComponent,
    CallMeBackInnerComponent,
    IframeThankYouComponent,
    SlidingArrowsComponent,
    HeroBannerComponent,
    MobileSliderComponent,
    CarouselComponent,
    DesktopSliderComponent,
    PopupVideoThumbsComponent,
    CampusInfoComponent,
    VideoSectionComponent,

    ScholarshipDetailCtrl,
    ScholarshipFormCtrl,
    ScholarshipOptinCtrl,
    ScholarshipResetPwdCtrl,
    ScholarshipRegisteredCtrl,
    CourseDetailCtrl
});

$(document).ready(() => {
    $('.hero-hp-container script').remove();
    //$('#hero-hp').remove();

    let $body = $('body');

    if ($body.find('.yxpMainContainer').length > 0 && $('.bodySurrogate').length > 0) {
        $body = $('.bodySurrogate');
    }

    $(document).on('keydown', 'input.only-numbers', (e) => {
        if ($(e.originalEvent.target).val().length >= 14 && e.originalEvent.keyCode != 46 && e.originalEvent.keyCode != 8) {
            e.preventDefault();
        } else {
            if (e.originalEvent.keyCode != 46 && e.originalEvent.keyCode != 8) {
                if (!!e.originalEvent.key && !!e.originalEvent.key.match(/[^\d]/g, '')) {
                    e.preventDefault();
                }
            }
        }
    });

    window.kdAiManager = new AiManager();

    $body.attr("component", 'CommonComponent');

    IMUtils.initCourseTable();

    if (!!$body && !!$body.attr('component')) {
        SmartComponentManager.initComponentByName($body[0], $body.attr('component'));
    }
});

